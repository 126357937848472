.App {
  text-align: center;
  height: 100%;
  overflow: scroll;
  padding: 0;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.app-body {
  background-color: #a4dbdc;
  height: fit-content;
  padding: 24px;
  align-items: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.navbar-img {
  width: 30px;
  height: auto;
}
.customNavbar {
  background-color: #fbb826;
  width: 100%;
  list-style: none;
}

.navLink {
  color: #ffffff;
  padding-right: 16px;
}

.navLink:hover {
  color: #2b2828;
}

.navLink:active {
  color: #2b2828;
  padding-right: 16px;
}
.home-active-link {
  color: #2b2828;
  padding-right: 16px;
}
.home-active-link:hover {
  color: #2b2828;
}
.download-active-link {
  color: #2b2828;
  padding-right: 16px;
}
.download-active-link:hover {
  color: #2b2828;
}
.info-active-link {
  color: #2b2828;
  padding-right: 16px;
}
.info-active-link:hover {
  color: #2b2828;
}
.terms-active-link {
  color: #2b2828;
  padding-right: 16px;
}
.terms-active-link:hover {
  color: #2b2828;
}
.footer-paragraph {
  font-family: Montserrat, sans-serif;
  padding: 16px;
}
.footerBottom {
  background-color: #fbb826;
  color: white;
}
.projects-component {
  width: 100%;
  height: fit-content;
  background-color: #fbb826;
  padding: 32px;
  border-bottom: 1px solid #fff;
}
.projects-block li {
  list-style: none;
  color: #fff;
  text-decoration: none;
  text-align: center;
}
.projects-title {
  text-align: center;
  color: #fff;
}
.projects-block {
  display: flex;
  justify-content: space-evenly;
  padding: 32px;
}
.projects-component a {
  color: #fff;
  text-decoration: none;
  text-align: center;
}
.mgc-logo {
  width: 4em;
  height: 4em;
}
.trivia-logo {
  width: 4em;
  height: 4em;
}
.links-group li {
  list-style: none;
}
.link-footer {
  display: block;
}
@media screen and (min-width: 1080px) {
  .home-slideshow {
    width: 100%;
    padding: 60px;
    background-image: url("slideshowbackground.png");
  }
}
@media screen and (max-width: 1080px) {
  .home-slideshow {
    width: 100%;
    height: 450px;
    padding: 60px;
    background-image: url("mobileslideshowb.png");
  }
}
.title-slideshow {
  display: flex;
  justify-content: center;
}

@media screen and (min-width: 1080px) {
  .trivia-title {
    font-weight: bold;
    color: #2b2828;
    font-size: 10em;
    text-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 30px #e5077e,
      0 0 40px #a4dbdc, 0 0 50px #e5077e, 0 0 60px #a4dbdc, 0 0 70px #e5077e;
  }
}
@media screen and (max-width: 1080px) {
  .trivia-title {
    font-weight: bold;
    color: #2b2828;
    font-size: 3em;
    text-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 30px #e5077e,
      0 0 40px #a4dbdc, 0 0 50px #e5077e, 0 0 60px #a4dbdc, 0 0 70px #e5077e;
  }
}
@keyframes brigth {
  from {
    text-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 30px #e5077e,
      0 0 40px #a4dbdc, 0 0 50px #e5077e, 0 0 60px #a4dbdc, 0 0 70px #e5077e;
  }
  to {
    text-shadow: 0 0 20px #fff, 0 0 30px #fff, 0 0 40px #e452a0,
      0 0 50px #b2d9da, 0 0 60px #e452a0, 0 0 70px #b2d9da, 0 0 80px #e452a0;
  }
}
@media screen and (min-width: 1080px) {
  .coin-title {
    color: #fbb826;
    font-weight: bold;
    font-size: 22em;
  }
}
@media screen and (max-width: 1080px) {
  .coin-title {
    color: #fbb826;
    font-weight: bold;
    font-size: 5em;
  }
}
.coin-title-element {
  display: inline-flex;
  align-items: center;
  padding: 16px;
  text-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 30px #e5077e, 0 0 40px #a4dbdc,
    0 0 50px #e5077e, 0 0 60px #a4dbdc, 0 0 70px #e5077e;
}

a {
  display: flex;
  text-decoration: none;
  font-weight: bold;
}

@media screen and (min-width: 1080px) {
  #circle {
    display: block;
    width: 20em;
    height: 20em;
    border: 1px;
    border-radius: 50%;
    overflow: hidden;
    margin: auto;
    position: relative;
    margin-bottom: 32px;
    animation: glow 0.2s ease-in-out infinite alternate;
  }
}
@media screen and (max-width: 1080px) {
  #circle {
    display: block;
    width: 6em;
    height: 6em;
    border: 1px;
    border-radius: 50%;
    overflow: hidden;
    margin: 8px;
    position: relative;
    animation: glow 0.2s ease-in-out infinite alternate;
  }
}
@keyframes glow {
  from {
    box-shadow: 0 0 10px #fff, 0 0 20px #fff, inset 20px 0 80px #e5077e,
      inset -20px 0 80px #a4dbdc, inset 20px 0 300px #e5077e,
      inset -20px 0 300px #a4dbdc, 0 0 50px #fff, -10px 0 80px #e5077e,
      10px 0 80px #a4dbdc;
  }
  to {
    box-shadow: 0 0 20px #fff, 0 0 30px #fff, inset 20px 0 80px #e452a0,
      inset -20px 0 80px #b2d9da, inset 20px 0 300px #e452a0,
      inset -20px 0 300px #b2d9da, 0 0 50px #fff, -10px 0 80px #e452a0,
      10px 0 80px #b2d9da;
  }
}

.quarter {
  display: inline-block;
  float: left;
  margin: 0;
  padding: 0;
  width: 50%;
  height: 50%;
}
#q1:hover {
  background-color: #03b2f7;
}
#q2:hover {
  background-color: #03ccae;
}
#q3:hover {
  background-color: #fbd426;
}
#q4:hover {
  background-color: #f27d0f;
}

#q1 {
  background-color: #018bc0;
  display: flex;
  justify-content: center;
  align-items: center;
}

#q2 {
  background-color: #01a68d;
}

#q3 {
  background-color: #fbb826;
}

#q4 {
  background-color: #f25c0f;
}
h2 {
  text-align: center;
}
.popover-card1 {
  background: #018bc0;
  text-align: center;
  color: #fff;
  padding: 16px;
}
.popover-card2 {
  background: #01a68d;
  text-align: center;
  color: #fff;
  padding: 16px;
}
.popover-card3 {
  background: #fbb826;
  text-align: center;
  color: #fff;
  padding: 16px;
}
.popover-card4 {
  background: #f25c0f;
  text-align: center;
  color: #fff;
  padding: 16px;
}
.btn-slideshow {
  background: #fbb826;
  border: #fbb826;
  display: block;
  font-weight: bold;
  width: fit-content;
  margin: auto;
  box-shadow: 1px 2px 2px #0000006e;
}
.btn-slideshow:hover {
  background-color: #f3ce7d;
  color: #fff;
  box-shadow: 1px 2px 2px #0000006e;
}
.btn-slideshow:active {
  background-color: #f3ce7d !important;
  border: #fbb826 !important;
  box-shadow: 1px 2px 2px #0000006e;
}
.coin-text {
  color: #fbb826;
  text-shadow: 0 0 2px #5f5f5fa6, 0 0 2px #5f5f5fa6;
  font-weight: bold;
}
.trivia-text {
  color: #2b2828;
  font-weight: bold;
  text-shadow: 0 0 2px rgba(189, 189, 189, 0.438),
    0 0 2px rgba(189, 189, 189, 0.438);
}
.title-text {
  display: inline-flex;
}
.game-text {
  margin-left: 16px;
  text-shadow: 0 0 2px rgba(90, 90, 90, 0.603),
    0 0 2px rgb(90, 90, 90, 90, 0.603);
  font-weight: bold;
  color: #2b2828;
}
.card {
  background-color: #2b2828;
  color: #000;
  box-shadow: 5px 5px 15px 5px #bbbbbb;
  border-radius: 16px;
}

.check-btn {
  margin-top: 16px;
  background-color: #e5077e;
  font-size: large;
  font-weight: bold;
  box-shadow: 1px 1px 1px #fff;
  border: none;
}
.check-btn:hover {
  background-color: #ec85bc;
  box-shadow: 1px 2px 2px #fff;
  color: #fff;
}
.play-again {
  margin-top: 16px;
  background-color: #e5077e;
  font-size: large;
  font-weight: bold;
  box-shadow: 1px 1px 1px #fff;
  border: none;
}
.play-again:hover {
  background-color: #ec85bc;
  box-shadow: 1px 2px 2px #fff;
  color: #fff;
}

.category {
  background-color: #e5077e;
  padding: 12px;
  margin-top: 16px;
  width: 200px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 5px;
  color: #fff;
}
.options {
  list-style-type: none;
  margin: 1rem 0;
  cursor: pointer;
  border-radius: 0.5rem;
  font-weight: 600;
  margin: 0.7rem 0;
  padding: 16px;
  cursor: pointer;
  background-color: #fbb826;
  color: #2b2828;
  text-shadow: 0 0 1px rgb(133, 132, 132);
}
.options:hover {
  background-color: #f3ce7d;
  color: #fff;
}
.options:active {
  transform: scale(0.97);
}
.options-selected {
  list-style-type: none;
  margin: 1rem 0;
  cursor: pointer;
  border-radius: 0.5rem;
  font-weight: 600;
  margin: 0.7rem 0;
  padding: 16px;
  cursor: pointer;
  background-color: #f3ce7d;
  color: #fff;
  text-shadow: 0 0 1px rgb(133, 132, 132);
}
.question {
  text-align: center;
  color: #fff;
}
.score {
  display: flex;
  color: #fff;
  font-weight: bold;
  background-color: #e5077e;
  width: fit-content;
  padding: 12px;
  margin: auto;
  margin-bottom: 16px;
  border: 2px solid #929292;
  border-radius: 5px;
}
.score p {
  margin: auto;
}
.final-score {
  color: #fff;
  font-weight: bold;
  background-color: #e5077e;
  width: fit-content;
  padding: 12px;
  margin: auto;
  margin-bottom: 16px;
  border: 2px solid #929292;
  border-radius: 5px;
}

.bold-text-result {
  color: #2b2828;
}
.alert-component {
  display: none;
}
.email-link h4 {
  color: #2b2828;
  font-weight: bold;
  display: block;
  align-items: center;
  margin-top: 64px;
  margin-left: auto;
  margin-right: auto;
}
.email-link:hover {
  text-decoration: underline;
  font-weight: bold;
  color: #2b2828;
}
.info-body {
  background-color: #a4dbdc;
  padding: 24px;
  align-items: center;
  font-size: calc(10px + 2vmin);
  color: white;
  font-size: calc(10px + 2vmin);
  color: white;
}
.email-txt {
  color: #ffffff;
  display: block;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
}
.email-txt:hover {
  color: #ffffff;
  font-weight: bold;
}
.terms-service-tittle {
  color: #2b2828;
  font-weight: bold;
}
.rights-reserved {
  margin-top: 32px;
  color: #2b2828;
  font-size: small;
  font-weight: bold;
}
.terms-service {
  display: block;
  color: #2b2828;
  margin: 24px auto;
  text-align: left;
  text-indent: 2em;
  padding: auto;
  width: 70%;
}
.terms-service p {
  font-size: 0.75em;
}
.title-page {
  color: #2b2828;
  font-weight: bold;
}
.cards-download {
  margin-top: 32px;
  display: flex;
  justify-content: center;
}
.card-box {
  display: block;
  margin: auto;
  background-color: #2b2828;
  color: #ffffff;
  width: 100%;
  height: 100%;
}
.card-img {
  width: 100px;
  display: block;
  margin: auto;
  padding: 20px;
}
.card-img-smll {
  width: 200px;
  display: block;
  margin: auto;
  padding: 20px;
}
.title-slideshow {
  color: #2b2828;
  text-shadow: #b3a5a5ee;
  font-weight: bold;
  margin-top: 16px;
  text-transform: uppercase;
}
